@tailwind base;
@tailwind components;
@tailwind utilities;

.richText {
  border: 0 !important;
  background: transparent !important;
}

.IconButton__root___3tqZW {
  border: 0 !important;
  background: transparent !important;
}

.projectList .ant-tabs-tab {
  width: 154px;
  justify-content: center;
}

.projectList .ant-tabs-tab+.ant-tabs-tab {
  margin-left: 0px;
}

.reviewSurvey .ant-tabs-tab {
  width: 154px;
  justify-content: center;
}

.reviewSurvey .ant-tabs-tab+.ant-tabs-tab {
  margin-left: 0px;
}

.projectList th {
  font-size: 14px !important;
  color: #828282 !important;
  padding-left: 27px;
}

.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 16px 13px !important;
}

.projectList .ant-table-thead>tr>th {
  background-color: #fff !important;
}

.projectList .ant-pagination {
  padding: 24px !important;
}

.ant-select-item-option-content {
  padding: 12px !important;
}

.ant-list-empty-text {
  display: none;
}

.divider {
  border: 1px solid #ececec;
}

.viewSurvey .ant-collapse-header {
  padding: 16px 0 !important;
}

.viewSurvey .ant-collapse-content-box {
  padding: 0 0 12px 0 !important;
}

.membersTab .ant-table-container .ant-table-content table {
  box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.1);
}

.surveyStats .ant-card-body {
  padding: 14px;
}

.custom-field {
  position: relative;
  height: 3rem;
}

.custom-field .ant-form-item-label {
  position: absolute;
  left: 34%;
  top: -2.5rem;
}

.custom-field .ant-form-item-control {
  position: absolute;
  left: 34%;
}

.selectMembers th {
  font-size: 14px !important;
  color: #828282 !important;
}

.selectMembers .ant-table-wrapper {
  border: 1px solid #ececec;
}

.projectList .ant-table-thead>tr>th {
  padding: 26px !important;
}
.ant-table-thead > tr > th {
  padding:  26px 13px !important;
}

.projectList .ant-pagination-total-text {
  font-family: Roboto;
  font-size: 14px;
  line-height: 36px;
  position: absolute;
  left: 26px;
  color: #e08036;
}

.ant-pagination-total-text {
  font-family: Roboto;
  font-size: 14px;
  line-height: 36px;
  position: absolute;
  left: 26px;
  color: #e08036;
}

.projectList .ant-pagination-prev {
  margin-right: 0;
  line-height: 0px !important;
}

.projectList .ant-pagination-next {
  margin-left: -8px;
  line-height: 0px !important;
}

.ant-pagination-item a {
  font-family: Roboto;
  font-size: 16px;

  color: #828282 !important;
}

.ant-pagination-item-active a {
  color: #e08036 !important;
}

.projectList .ant-pagination-item-active {
  border-color: #dcdcdc;
}

.projectList .ant-pagination-item-link {
  border-radius: 0;
}

.projectList .ant-pagination-prev .ant-pagination-item-link {
  border-right: 0;
  border-radius: 5px 0px 0px 5px;
}

.projectList .ant-pagination-next .ant-pagination-item-link {
  border-radius: 0px 5px 5px 0px;
  border-left: 0;
}

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next,
.ant-pagination-item {
  height: 40px !important;
  min-width: 44px !important;
  line-height: 38px !important;
}

.viewProject .ant-collapse>.ant-collapse-item>.ant-collapse-header {
  background-color: #fff;
  padding-left: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #4f4f4f;
}

.viewProject .ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  top: initial;
}

.viewProject .ant-collapse,
.viewProject .ant-collapse-content {
  border: none;
}

.viewProject .ant-collapse-content>.ant-collapse-content-box {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  font-size: 14px;
  line-height: 22px;
  color: #828282;
}

.memberTable .ant-table-measure-row {
  display: none;
}

.table-border-0 {
  border: 0 !important;
}

.search-icon {
  color: #9ca3af !important
}

.column-custom-searchicon svg {
  width: 12px;
}

.show_error_modal .ant-modal-header {
  border-bottom: 1px solid #e8e8e8 !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.show_error_modal .ant-modal-close-x {
  font-size: 20px;
  color: #000 !important;
  cursor: pointer;
}

.show_error_modal .ant-modal-close-x:hover {
  color: #1890ff !important;
}

.show_error_modal_content {
  /* Add your custom styling for the modal content */
}
.table-border-0{
  border:0 !important;
}
.disabled-row {
  color: #00000040 !important;
  cursor: no-drop;
}
.column_Editor_body {
  max-height: 50vh;
  min-height: auto;
  height: auto;
  overflow-y: auto;
}

.column_Editor_body::-webkit-scrollbar,
.body_status::-webkit-scrollbar {
  width: 6px;
}

.column_Editor_body::-webkit-scrollbar-track,
.body_status::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.column_Editor_body::-webkit-scrollbar-thumb,
.body_status::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 2px;
}

.column_Editor_body::-webkit-scrollbar-thumb:hover,
.body_status::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.body_status{
  max-height: 45vh;
  overflow: auto;
}
.import_dragger .import_upload .ant-upload-btn{
  padding: 0px 0 !important;
}
  